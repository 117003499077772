<template>
  <s-dialog
    :visible="visible"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    class="resume-shipment-dialog"
    @update:visible="updateVisible"
    @close-from-icon="triggerNotice('close')"
    @close="onClosed"
    @open="onOpen"
  >
    <div 
      v-if="info && info.msg"
      class="resume-shipment-text"
      v-html="info.msg"
    >
    </div>
    <div class="resume-shipment-text">
      {{ language.SHEIN_KEY_PWA_34951 }}
    </div>
    <s-loading
      v-if="showLoading"
      type="curpage"
      :show="showLoading"
    />
    <template #footer>
      <div class="resume-shipment-dialog__footer">
        <s-button
          :type="['primary', 'H44PX']"
          @click="onConfirm"
        >
          {{ language.SHEIN_KEY_PWA_34358 }}
        </s-button>
        <s-button
          :type="['H44PX']"
          @click="onCancel"
        >
          {{ language.SHEIN_KEY_PWA_34361 }}
        </s-button>
      </div>
    </template>
  </s-dialog>
</template>

<script>
import { mapMutations } from 'vuex'
import schttp from 'public/src/services/schttp'
import { isFunction } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '1-20-1' })

export default {
  name: 'ResumeShipmentDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object,
      default: () => {}
    },
    orderInfo: {
      type: Object,
      default: () => {}
    },
    info: {
      type: Object,
      default: () => {}
    },
    asyncCallbacks: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isSuccess: false,
      showLoading: false,
    }
  },
  methods: {
    ...mapMutations(['changeRootStatus']),
    updateVisible(event) {
      this.$emit('update:visible', event)
    },
    changeLoading(loadingStatus = false) {
      this.showLoading = loadingStatus
    },
    triggerNotice(operation = '') {
      daEventCenter.triggerNotice({
        daId: '1-20-1-181',
        extraData: {
          order_id: this.orderInfo?.billno || '',
          scene: this.info?.scene || '',
          operation,
        },
      })
    },
    async onConfirm() {
      this.triggerNotice('resume')
      this.changeLoading(true)
      const {
        billno = '',
        order_type = '',
        order_mark_list = []
      } = this.orderInfo || {}
      const markItem = (order_mark_list || []).find(i => i.mark_type == 1 && i.mark_type_status == 1)

      const res = await schttp({
        method: 'POST',
        url: '/api/orders/base/orderMark/update',
        data: {
          billNo: billno,
          firstMarkType: 1, // 一级类别
          secondMarkType: markItem ? markItem.trouble_reason : '', // 二级类别
          orderType: order_type || '',
          type: 2, // 操作类型：1 为标记 2为取消
        }
      }).catch(() => {
        this.changeLoading(false)
      })
      this.changeLoading(false)
      if (res?.code == 0) {
        this.isSuccess = true
        setTimeout(() => {
          this.$toast(this.language.SHEIN_KEY_PWA_34362)
        }, 500)
        this.updateVisible(false)
      } else {
        this.$toast(this.language.SHEIN_KEY_PWA_34360)
      }
    },
    onClosed() {
      if (Array.isArray(this.asyncCallbacks) && this.asyncCallbacks.length > 0) {
        this.asyncCallbacks.forEach(cb => {
          if (isFunction(cb)) {
            cb(this.isSuccess)
          }
        })
      }
      this.isSuccess = false
    },
    onOpen(){
      daEventCenter.triggerNotice({
        daId: '1-20-1-180',
        extraData: {
          order_id: this.orderInfo?.billno || '',
          scene: this.info?.scene || ''
        },
      })
    },
    onCancel() {
      this.triggerNotice('pause')
      this.updateVisible(false)
      this.$emit('cancel')
    }
  }
}
</script>
<style lang="less">
.resume-shipment-dialog {
  .resume-shipment-text {
    color: @sui_color_gray_dark1;
    font-size: 28/75rem;
    font-weight: 400;
    line-height: normal;
    text-align: left;

    & + .resume-shipment-text {
      margin-top: 40/75rem
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24/75rem;

    button {
      width: 100%;
    }
  }
}
</style>
